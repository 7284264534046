
module DBS.Gage
{
    export function SetupGage(id: string, value: number, min?: number, max?: number): any
    {
        return {
            id: id,
            value: value,
            min: min ?? 0,
            max: max ?? 1,
            gaugeWidthScale: 0.5,
            levelColors: ["#f86c6b", "#ffc107", "#4dbd74"],
            hideValue: true,
            hideMinMax: true,
            startAnimationTime: 3000,
            pointer: true,
            pointerOptions: {
                bottomwidth: 2.5,
                bottomlength: 40,
                toplength: -5,
                stroke: '#000',
                stroke_width: 1,
                stroke_linecap: 'round',
                color: '#000'
            }
        };
    }
}